import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/university/list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/university/get?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/university/save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/university/save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/university/delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sportItems(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/sports/list')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDepartmentList(ctx, {universityId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/university/department-list?university_id=${universityId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDepartment(ctx, {universityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/university/department-get?university_id=${universityId}&id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDepartment(ctx, {universityId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/university/department-save?university_id=${universityId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editDepartment(ctx, {universityId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/university/department-save?university_id=${universityId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeDepartment(ctx, {universityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/university/department-delete?university_id=${universityId}&id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        importCsv(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/university/import-csv', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
